
class EndpointsService {
    getApiV1Endpoint(){
        return "https://apizavese.b1n3.net/api/v1/"
        //return "http://localhost:8080/api/v1/"
        //return "http://10.10.10.59:8080/api/v1/"
    }
}

export default new EndpointsService();
