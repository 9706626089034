import axios from 'axios';
import authHeader from "../headers/auth-header";
import EndpointService from "./endpoints.service"


const API_URL = EndpointService.getApiV1Endpoint();

class AuthService {
    login(otp) {
        return axios.get(API_URL + "authentication/login", { headers: { Authorization: otp } })
    }

    logout() {
        return axios
            .get(API_URL + "authentication/logout", { headers: authHeader() })
            .then(
                localStorage.removeItem('user')
            );
    }

    check() {
        return axios.get(API_URL + "authenticate/check", { headers: authHeader(), responseType: "json", timeout: 10000 })
    }
}

export default new AuthService();
