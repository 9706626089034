import axios from 'axios';
import authHeader from "../headers/auth-header";
import EndpointService from "./endpoints.service"


const API_URL = EndpointService.getApiV1Endpoint();

class CategoryService {

    getAll() {
        return axios.get(API_URL + "categories/get-all", { headers: {"Content-Type": "application/json; charset=UTF-8"} })
    }

    getAllChildren(id) {
        return axios.get(API_URL + "categories/get-children/" + id, { headers: {"Content-Type": "application/json; charset=UTF-8"} })
    }

    getCategory(id) {
        return axios.get(API_URL + "categories/" + id, { headers: authHeader() })
    }

    getAllForSelection() {
        return axios.get(API_URL + "categories/get-all-for-selection", { headers: authHeader() })
    }

}

export default new CategoryService();
