import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import('./views/home.vue'),
        },
        {
            path: '/home',
            component: () => import('./views/home.vue'),
        },
        {
            path: '/category',
            component: () => import('./views/category.vue'),
        },
        {
            path: '/item',
            component: () => import('./views/item.vue'),
        },
        {
            path: '/cart',
            component: () => import('./views/cart.vue'),
        },
        {
            path: '/info',
            component: () => import('./views/info.vue'),
        }
    ],
    navigationFallback: {
        "rewrite": "/index.html",
        "exclude": ["/images/*", "/css/*"]
    }
});


