<template>
  <div class="root-div">

    <router-link :to="'/category?id=' + item.id" class="router-link">
      <v-icon v-if="isChild" class="icon">mdi-arrow-right-bottom</v-icon>
      <span v-if="$locale === 'en'" :style="getStyleForText()">{{ item.nameEN }}</span>
      <span v-else-if="$locale === 'de'" :style="getStyleForText()">{{ item.nameDE }}</span>
      <span v-else :style="getStyleForText()">{{ item.nameSI }}</span>
    </router-link>

    <div v-if="hasChildren" style="margin-left: 10px">
      <MenuItem
          v-for="item in item.children"
          :key="item.id"
          :item="item"
          :child="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    child: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    hasChildren() {
      const { children } = this.item
      return children && children.length > 0
    },
    isChild(){
      return this.child !== undefined && this.child === true;
    }
  },
  methods: {
    getStyleForText(){
      if(this.isChild)
        return "margin-left: 15px; font-weight: normal;";
      else
        return "font-weight: bold;";
    }
  }
}
</script>

<style scoped>

  .root-div {

  }

  .icon {
    position: relative;
    top: -5px;
  }


  .router-link {
    height: 35px;
    padding-top: 9px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 15px;
    font-size: 18px;
    font-family: Source Sans Pro, sans-serif;
    color: #171717;
  }

  a {
    display: block;
    color: #9FAEC1;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  .router-link-exact-active {
    background: #9FBB38;
    border-radius: 5px;
  }

  a:hover:not(.router-link-active) {
    background-color: rgba(217, 217, 217, 0.3);
  }

</style>
