<template>
  <v-app>
    <div>
      <div class="topBar">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up" style="color: white"></v-app-bar-nav-icon>
        <img src="../public/logo.png" alt="Zavese" class="logo"/>
        <div class="topBarButtons">
          <router-link to="/cart"><v-icon style="color: white">mdi-cart</v-icon><br>Košarica</router-link>
          <router-link to="/info"><v-icon style="color: white">mdi-information-outline</v-icon><br>Informacije</router-link>
          <router-link to="/home"><v-icon style="color: white">mdi-home</v-icon><br>Domov</router-link>
        </div>
      </div>
      <div class="sidenav">
        <MenuItem v-for="category in categories" :key="category.id" :item="category" />
        <hr style="margin-top: 20px; margin-bottom: 30px; margin-left: 15px; margin-right: 15px">
        <a @click="setLocale('en')" style="margin-left: 48px">EN</a>
        <a @click="setLocale('de')" style="margin-left: 62px">DE</a>
        <a @click="setLocale('si')" style="margin-left: 62px; padding-bottom: 75px">SI</a>
      </div>
      <v-navigation-drawer
          v-model="drawer"
          temporary
          absolute
          width = "320"
          style="background-color: #e2e2e2; calc(100% - 48px); height: 100vh;"
      >
        <div style="overflow: scroll">
          <img src="../public/logo.png" alt="Zavese" class="logo-mobile"/>
          <hr style="margin-top: 20px; margin-bottom: 25px; margin-left: 15px; margin-right: 15px">
          <router-link to="/home" class="drawer-navigation-button">Domov</router-link>
          <router-link to="/info" class="drawer-navigation-button">Informacije</router-link>
          <router-link to="/cart" class="drawer-navigation-button">Košarica</router-link>
          <hr style="margin-top: 20px; margin-bottom: 30px; margin-left: 15px; margin-right: 15px">
          <MenuItem v-for="category in categories" :key="category.id" :item="category" />
          <hr style="margin-top: 20px; margin-bottom: 30px; margin-left: 15px; margin-right: 15px">
          <a @click="setLocale('en')" style="margin-left: 62px">EN</a>
          <a @click="setLocale('de')" style="margin-left: 62px">DE</a>
          <a @click="setLocale('si')" style="margin-left: 62px; padding-bottom: 75px">SI</a>
        </div>

      </v-navigation-drawer>

      <div class="main-div">
        <router-view/>
      </div>


      <v-btn
          fab
          color="primary"
          large
          class="cart-button hidden-md-and-up"
          @click="$router.push('/cart')"
      >
        <v-icon>mdi-cart</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
  import CategoryService from "@/services/categoty.service";
  import MenuItem from "@/components/MenuItem";

  export default {
    name: 'App',
    components: {
      MenuItem
    },
    computed: {
      currentPath(){
        return this.$router.path
      }
    },
    data() {
      return {
        categories: [],
        loadingCategories: false,
        drawer: "",
        locale: "",
      }
    },
    mounted() {
      this.getAllCategories();
      if(localStorage.getItem("zavese-locale") === null){
        localStorage.setItem("zavese-locale", "si")
      }
      this.locale = localStorage.getItem("zavese-locale")
    },
    watch:{
      $route (){
        console.log("Link changed. Closing drawer")
        this.drawer = false
      }
    },
    methods: {
      getAllCategories(){
        this.loadingCategories = true;
        CategoryService.getAll().then(
            response => {
              this.categories = response.data;
              this.loadingCategories = false;
            },
            error => {
              console.log(error)
              this.loadingCategories = false;
              this.displayMessage = error.response.data.message
              this.snackbarColor = "#F44336"
              this.snackbar = true
            }
        );
      },
      setLocale(locale){
        localStorage.setItem("zavese-locale", locale)
        this.$router.go()
      }
    }
  }
</script>

<style scoped>
    .topBar{
        position: sticky;
        height: 100px;
        background-color: #656565;
        padding-right: 50px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 50px;
    }
    .topBar a {
      display: block;
      color: #eeeeee;
      text-align: center;
      padding: 12px;
      width: 120px;
      height: 100%;
      float: right;
      text-decoration: none;
      font-size: 18px;
      border-radius: 5px;
      margin-left: 10px;
      transition: 1s;
    }
    .topBar a:hover:not(.active) {
      background-color: rgba(217, 217, 217, 0.3);
      color: white;
      transition: 0.1s;
    }
    .topBarButtons{
      float: right;
    }
    .topBarButtons{
        float: right;
    }
    .router-link-active {
      background: #9FBB38;
      border-radius: 5px;
    }


    .logo{
      height: 50px;
      margin: 0;
      margin-top: 15px;
    }
    .logo-mobile{
      height: 50px;
      margin: 0;
      margin-top: 35px;
      margin-left: 15px;
    }
    .toggle-button {
      position: fixed;
      bottom: 20px;
      left: 20px;
      z-index: 1000;
    }
    .cart-button {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1000;
    }
    .sidenav {
      height: 100vh;
      overflow: auto;
      padding: 10px;
      width: 300px;
      background-color: #f5f5f5;
      float: left;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
    .main-div {
      float: right!important;
      width: calc(100% - 300px);
    }

    .drawer-navigation-button {
      display: block;
      color: #232323;
      text-align: center;
      padding: 4px;
      width: 95%;
      height: 40px;
      text-decoration: none;
      font-size: 22px;
      border-radius: 20px;
      transition: 0.1s;
      margin-left: 2%;
    }

    @media only screen and (max-width: 960px){
      .topBar{
        height: 60px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 10px;
      }
      .topBarButtons{
        display: none;
      }
      .logo{
        height: 100%;
        margin: auto;
        float: right;
      }
      .sidenav {
        display: none;
      }
      .main-div {
        width: 100%;
      }
    }

    @media only screen and (max-width: 400px){

    }

</style>
